.react-flow__node {
    background: transparent;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-flow__node input {
    margin-top: 5px;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid transparent;
    background: transparent;
    color: white;
}

.react-flow__node.default input {
    background: transparent;
}

.react-flow__handle {
    width: 10px;
    height: 10px;
    background: #15a2fa;
    border: 2px solid white;
    border-radius: 50%;
}

.react-flow__node.inputNode .react-flow__handle,
.react-flow__node.summingPointNode .react-flow__handle,
.react-flow__node.pidNode .react-flow__handle,
.react-flow__node.plantNode .react-flow__handle,
.react-flow__node.scopeNode .react-flow__handle,
.react-flow__node.unityNode .react-flow__handle {
    width: 10px;
    height: 10px;
    background: #15a2fa;
    border: 2px solid white;
    border-radius: 50%;
}

.unityNode {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: white;
    border-radius: 5px;
    padding: 10px;
    border: 2px solid transparent;
}

.unity-node {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    width: 60px;
    height: 40px;
}

.unity-label {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

