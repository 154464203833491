/* DiagramDecoder.css */
.sidebar {
    width: 10%;
    padding: 10px;
    background: #2a2a2a;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-title {
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.node-item {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: grab;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    transition: background 0.3s, transform 0.3s;
}

.node-item:hover {
    background: #3a3a3a;
    transform: scale(1.05);
}

.node-icon {
    color: #ffc801;
    margin-right: 10px;
    font-size: 1.5em;
}
