.custom-toolbar-node {
    position: relative;
    background: transparent;
    padding: 10px;
    /*border: 1px solid #fff;*/
    border-radius: 5px;
}
.custom-resizer-node {
    position: relative;
    background: transparent;
    padding: 10px;
    /*border: 1px solid #fff;*/
    border-radius: 5px;
}

.react-flow__node.selected .custom-resizer-node {
    border: 1px solid #fff !important;
}

.react-flow__node .react-flow__handle {
    background: #fff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.react-flow__node:hover .react-flow__handle {
    background: #ffd700;
}
