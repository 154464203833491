:root {
	--pink: #fbb9c5;
	--purple: #e6c8fe;
	--cream: #fcf7e3;
	--green: #c3edbf;
	--blue: #b8dfe6;
	--darkPink: #f17891;
	--darkPurple: #b99ef5;
	--white: #353535;
	--black: #fffffe;
	--lightBlue: #fffffe;
}

.navbar {
	flex-direction: column;
	height: auto;  /* Set to auto for dynamic height */
	width: 100%;
	background: var(--white);
	color: var(--lightBlue);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 2rem;
	font-size: 1rem;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.nav-row {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 0;  /* Add padding to create spacing between rows */
	width: 100%;  /* Ensure row takes up full width */
	flex-wrap: wrap;  /* Allow content to wrap onto next line if it overflows */
}

.nav-row-logo {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;  /* Ensure logo row takes up full width */
	text-align: center;  /* Center text */
}

.nav-img {
	width: 50px;
	height: 50px;
	margin-right: 7px;
	margin-left: 7px;
	margin-bottom: 12px;
}

.nav-logo {
	text-decoration: none;
	color: var(--lightBlue);
	font-size: 2rem;
	font-family: 'Poppins', sans-serif;
}

.navbar li > .active {
	font-weight: bold;
	color: var(--pink);
}

ul.nav-links {
	margin-right: 10px;
}

.nav-links {
	display: flex;
	justify-content: center;
	list-style: none;
	width: 100%;
}


.nav-link {
	text-decoration: none;
	color: gray;
	transition: 0.3s all;
	padding: 0;
	margin: 0 2rem;  /* Add horizontal margin to each link */
}


.nav-link:hover {
	color: var(--pink);
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

.nav-btn {
	padding: 0px 15px;
	border: 1px solid var(--blue);
	border-radius: 5px;
	background: var(--blue);
	color: var(--white);
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	text-align: center;
	appearance: menulist-button;
	align-items: center;
	justify-content: center;
}

.nav-text {
	font-size: 16px;
	text-align: center;
	font-style: italic;
}

.nav-image {
	border-radius: 50%;
	width: 45px;
	height: 45px;
	object-fit: cover;
}

@media only screen and (max-width: 800px) {
	.navbar {
		position: relative;
	}

	.nav-row {
		flex-direction: column;
		justify-content: center;
	}

	.nav-links {
		display: flex;
		flex-direction: column;
		position: absolute;
		text-align: center;
		width: 100%;
		top: 70px;
		left: -100%;
		transition: 0.5s all;
		z-index: 1000;
	}

	.nav-links.active {
		background: var(--white);
		left: 0;
	}

	.nav-item {
		padding: 10px 0;
		width: 100%;
		border: 1px solid lightgray;
	}

	.nav-icon {
		display: flex;
	}
}
