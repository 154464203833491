/* DiagramMermaid.css */
.sidebar {
    width: 20%;
    background-color: #333;
    border-right: 1px solid #444;
    padding: 10px;
    color: white;
}

.sidebar-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.node-item {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #555;
    cursor: pointer;
    color: white;
}

.node-item:hover {
    background-color: #666;
}

.mermaid {
    width: 100%;
    height: 100%;
    overflow: auto;
    color: white;
}
