/* Increase specificity */
html body {
  margin: 0;
  padding: 0;
  background-color: #2a2a2a; /* Dark background color */
  color: white; /* Light text color */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Use of !important to ensure these styles take precedence */
html body {
  background-color: #2a2a2a !important;
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.post-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.post {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 10px;
  background-color: #2a2a2a;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.post img {
  width: 100%;
  border-radius: 10px;
}

.post h3 {
  margin: 0;
  margin-bottom: 0.5rem;
}
